var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-skeleton-loader',{attrs:{"loading":_vm.isSkeletonLoading,"height":"600","type":"table"}},[_c('v-data-table',{staticClass:"patient-list-page",attrs:{"item-key":"name","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totals,"footer-props":_vm.tableFooterProps},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":_vm.renderPatientReportList,"update:page":_vm.onChangePagination},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('main.report.search'),"outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.renderPatientReportList($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.viewPatientInfo(item)}}},[_vm._v(_vm._s(item.fullName))])]}},{key:"item.pastServices",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[(!item.pastServices.length)?_c('span',[_c('b',[_vm._v(_vm._s(_vm.$t("main.report.dosentPatient")))])]):_vm._l((item.pastServices),function(s){return _c('v-chip',{key:s.serviceID,staticClass:"ma-1 justify-center",attrs:{"dark":""}},[_vm._v(" "+_vm._s(s.serviceName)+" ")])})],2)]}},{key:"item.genderType",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('GenderItem',{attrs:{"genderType":item.genderType}})],1)]}},{key:"item.dob",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dob))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }