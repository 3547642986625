<template>
  <v-skeleton-loader :loading="isSkeletonLoading" height="600" type="table">
    <v-data-table
      class="patient-list-page"
      item-key="name"
      :headers="headers"
      :items="items"
      :search="search"
      :options.sync="options"
      :server-items-length="totals"
      :footer-props="tableFooterProps"
      @update:items-per-page="renderPatientReportList"
      @update:page="onChangePagination"
    >
      <template v-slot:top>
        <v-row align="center">
          <v-col cols sm="6" md="4">
            <v-text-field
              :label="$t('main.report.search')"
              v-model="search"
              outlined
              v-on:keyup.enter="renderPatientReportList"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.fullName`]="{ item }">
        <a @click="viewPatientInfo(item)">{{ item.fullName }}</a>
      </template>

      <template v-slot:[`item.pastServices`]="{ item }">
        <div class="d-flex flex-column">
          <span v-if="!item.pastServices.length">
            <b>{{ $t("main.report.dosentPatient") }}</b>
          </span>
          <v-chip
            v-else
            v-for="s in item.pastServices"
            :key="s.serviceID"
            dark
            class="ma-1 justify-center"
          >
            {{ s.serviceName }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.genderType`]="{ item }">
        <div class="d-flex flex-column">
          <GenderItem :genderType="item.genderType" />
        </div>
      </template>

      <template v-slot:[`item.dob`]="{ item }">
        {{ formatDate(item.dob) }}
      </template>
    </v-data-table>
  </v-skeleton-loader>
</template>

<script>
import { TABLE_FOOTER_PROPS } from "@/plugins/constant";
import WorkListService from "@/services/work-list";
import GenderItem from "@/components/GenderItem";
import moment from "moment";
import i18n from "@/plugins/i18n";

const HEADER_PATIENT = [
  {
    text: i18n.t("main.patient.patientID"),
    align: "center",
    sortable: false,
    value: "patientID",
  },
  {
    text: i18n.t("main.patient.patientName"),
    align: "center",
    sortable: false,
    value: "fullName",
  },
  {
    text: i18n.t("main.patient.gender"),
    align: "center",
    sortable: false,
    value: "genderType",
  },
  {
    text: i18n.t("main.patient.dateOfBirth"),
    align: "center",
    sortable: false,
    value: "dob",
  },
  {
    text: i18n.t("main.patient.email"),
    align: "start",
    sortable: false,
    value: "email",
  },
  {
    text: i18n.t("main.patient.phone"),
    align: "start",
    sortable: false,
    value: "phoneNumber",
  },
  {
    text: i18n.t("main.patient.service"),
    align: "center",
    sortable: false,
    value: "pastServices",
  },
];

export default {
  name: "ReportPage",
  components: {
    GenderItem,
  },
  data() {
    return {
      isSkeletonLoading: true,
      search: "",
      options: {
        page: 1,
        itemsPerPage: 20,
      },
      statusID: 1,
      totals: 0,
      items: [],
      headers: HEADER_PATIENT,
      tableFooterProps: TABLE_FOOTER_PROPS,
    };
  },
  async mounted() {
    await this.renderPatientReportList();
  },
  watch: {
    "$route.path"() {
      if (this.$route.path == "/home/report/finish") {
        this.statusID = 1;
        this.renderPatientReportList();
      } else {
        this.statusID = 2;
        this.renderPatientReportList();
      }
    },
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD/MM/YYYY");
    },
    viewPatientInfo(item) {
      var { patientID, fullName } = item;
      var key = `/patient/${patientID}`;
      var label = fullName;
      var tabData = {
        key,
        label,
      };
      parent.postMessage({ event: "ADD_HEADER_TAB", data: tabData }, "*");
    },
    async renderPatientReportList(isShowLoading = true) {
      const keySearch = this.search;
      var timeStart = moment().startOf("date").format("YYYY-MM-DD HH:mm");
      var timeEnd = moment().endOf("date").format("YYYY-MM-DD HH:mm");
      const { page, itemsPerPage } = this.options;
      this.isSkeletonLoading = isShowLoading;
      var result = await WorkListService.searchReportsWithPastServices(
        keySearch,
        timeStart,
        timeEnd,
        this.statusID,
        page,
        itemsPerPage
      );

      this.isSkeletonLoading = false;
      if (!result || result.error) {
        this.showError(
          "Cannot get patient report list! Please try again later!"
        );
        return;
      }
      var { items, totals } = result;
      items.forEach((item) => {
        for (var key in item) {
          if (!item[key]) {
            item[key] = "N/A";
          }
        }
      });
      this.totals = totals;
      this.items = items;
    },
    async onChangePagination(value) {
      this.options.page = value;
      await this.renderPatientReportList();
    },
  },
};
</script>

<style lang="scss">
.patient-list-page {
  margin-top: 24px;
}
</style>