import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
import { startOfDay, endOfDay } from "../plugins/helper";
import AppConstant from '../plugins/constant'


var httpClient = new HttpClient(ApiGateway.Emr);

export default {
  // Search Queue Work List by doctorID, default search is today
  SearchByDoctorID(doctorID = "", keySearch = "", statusID = 1, start = "", end = "", page = 1, limit = 10) {
    start = start || startOfDay();
    end = end || endOfDay();

    var query = {
      doctorID,
      keySearch,
      statusID,
      start,
      end,
    }
    var headers = {
      page,
      limit,
    }
    return httpClient.get('/WorkList/Doctor/Search', query, headers);
  },

  // Search Queue Work List by roomID, default search is today
  SearchByRoomID(roomID, keySearch = "", statusID = 1, start = "", end = "", page = 1, limit = 10) {
    start = start || startOfDay();
    end = end || endOfDay();

    var query = {
      id: roomID,
      keySearch,
      statusID,
      start,
      end,
    }
    var headers = {
      page,
      limit,
    }
    return httpClient.get('/WorkList/Room/Search/{id}', query, headers);
  },

  // Search Booking Work List with by doctorID, default search is today
  SearchBookingByDoctorID(doctorID, keySearch = "", statusID = 1, start = "", end = "", page = 1, limit = 10) {
    start = start || startOfDay();
    end = end || endOfDay();

    var query = {
      id: doctorID,
      keySearch,
      statusID,
      start,
      end,
    }
    var headers = {
      page,
      limit,
    }
    return httpClient.get('/WorkList/Booking/Search/{id}', query, headers);
  },
  countPatientsReport: async (timeStart, timeEnd) => {
    var query = {
      timeStart,
      timeEnd,
    }
    return httpClient.get(`WorkList/Report/Count`, query);
  },
  searchPatientsReport: async (keySearch, timeStart, timeEnd, statusID = 2, page = 1, limit = 10) => {
    var query = {
      keySearch,
      timeStart,
      timeEnd,
      statusID,
    }
    var headers = {
      page,
      limit: limit || AppConstant.DefaultPaginationLimit,
    }
    return httpClient.get(`WorkList/Report/Search`, query, headers);
  },
  searchReportsWithPastServices: async (keySearch, timeStart, timeEnd, statusID = 2, page = 1, limit = 10) => {
    var query = {
      keySearch,
      timeStart,
      timeEnd,
      statusID,
    }
    var headers = {
      page,
      limit: limit || AppConstant.DefaultPaginationLimit,
    }
    return httpClient.get(`WorkList/Report/SearchReportWithPastService`, query, headers);
  },
  // Get Work List item by EpisodeRecordID
  GetByEpisodeRecordID(id) {
    return httpClient.get('/WorkList/EpisodeRecord/GetByID/{id}', { id });
  },
  // update work-list item success
  completeOrderItem: async (orderID) => {
    var query = {
      service: false,
    }
    return httpClient.put(`/Order/Complete/${orderID}`, query);
  },
  // get order by id
  getAxPxByTargetID(targetID) {
    return httpClient.get(`/AxPx/Get/${targetID}`);
  },
}